import React, { Component, Suspense } from "react";
import {
  Container,
  Col,
  Row
} from "reactstrap";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import '../styles/error-404.css';
import logo from "../../static/plug.svg";
import { Header, SliceZone, Footer } from "components";

class ErrorPage404 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var isWindow = typeof window !== 'undefined';
    const {
      prismicHeromenu,
      prismicFooter,
      allPrismicPage,
      allPrismicResource,
      prismicCareerFooter
    } = this.props.data;
    let pageURL = this.props.location.href;

    return (
      <div className="error-page">
        {
          isWindow && (
            <Suspense fallback={<div/>}>
              <Helmet>
                <title>Page Not Found | GSPANN</title>
                <meta name="robots" content="noindex, nofollow" />
                <meta http-equiv="Pragma" content="no-cache" />
                <meta http-equiv="Expires" content="0" />
                <meta name="google-site-verification" content="Fy8VROwdCIk4KmBbEnJGko3-ZXm9agz417MGKGPUBP8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="canonical" href={pageURL} hrefLang="en"/>
                <link rel="alternate" href={pageURL} hrefLang="en" key="en" />
              </Helmet>
              <Header
                menuData={prismicHeromenu.data.nav}
                pagesLinksData={allPrismicPage.edges.concat(allPrismicResource.edges)} />
              <Container className="error-container">
                <Row className='error-row' noGutters>
                  <Col xs={12} sm={6} noGutters>
                      <Row>
                        <Col><h1 className="error-heading"> Oops!</h1></Col>
                      </Row>
                      <Row>
                        <Col><h3 className="error-message">We can't seem to find the page you're looking for.</h3></Col>
                      </Row>
                      <Row>
                        <Col><h5 className="error-code"> Error code 404</h5></Col>
                      </Row>
                      <Row>
                        <Col><h6 className="redirect-label">Try these links instead:</h6></Col>
                      </Row>
                      <Row>
                        <Col>
                          <a href="/" hrefLang="en">Home</a>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <a href="/jobs/find-a-position/" hrefLang="en">Jobs</a>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <a href="/resources/all-resources/" hrefLang="en">Resources</a>
                        </Col>
                      </Row>
                  </Col>
                  <Col xs={12} sm={6}>
                    <img
                      src={logo}
                      alt="error plug"
                      className='error-image'
                    />
                  </Col>
                </Row>
              </Container>
              <Footer
                footerData={prismicCareerFooter.data}
                pagesLinksData={allPrismicPage.edges.concat(allPrismicResource.edges)}
              />
            </Suspense>
          )
        }
      </div>
    );
  }
}

export default ErrorPage404;

export const errorPage404Query = graphql`
query  ErrorQuery{
  prismicHeromenu {
    id
    data {
      display_name {
        html
      }
      nav {
        primary {
          label {
            html
            text
          }
          link {
            url
          }
          label_font_color
          label_font_color_on_hover
          nav_item_background_color
          sub_link_separator_line_color
          sub_link_sub_text_font_color
          sub_link_type_font_color
          sub_nav_link_label_font_color
          nav_column_count
          nav_item_position
        }
        items {
          sub_nav_column_position
          sub_nav_link_lable {
            text
          }
          sub_nav_link {
            url
          }
          sub_link_sub_text {
            text
          }
          sub_link_type {
            text
          }
        }
      }
    }
  }
  prismicCareerMenu {
    id
    data {
      menu_links {
        link_label {
          text
        }
        link {
          document {
            id
            uid
            href
            data {
              page_path {
                text
              }
              page_display_name {
                text
              }
            }
          }
          url
        }
      }
    }
  }
  allPrismicPage {
    edges {
      node {
        id
        uid
        data {
          page_display_name {
            text
          }
          page_path {
            text
          }
        }
      }
    }
  }
  allPrismicResource(filter: {data: {page_path: {text: {ne: "campaign"}}}}) {
    edges {
      node {
        uid
        data {
          resource_display_name {
            text
          }
          page_path {
            text
          }
          body {
            __typename
            ... on PrismicResourceBodyPageOverview {
              primary {
                page_thumbnail {
                  url
                  alt
                }
              }
            }
            ... on PrismicResourceBodyResourceOverview {
              primary {
                resource_type
                title {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
  prismicFooter {
    data {
      footer_background_color
      footer_font_color
      address {
        text
      }
      copyright_text {
        text
      }
      footer_logo {
        url
      }
      body {
        __typename
        ... on PrismicFooterBodySocialConnection {
          id
          slice_type
          primary {
            social_connection_type {
              text
            }
            social_connection_font_color
          }
          items {
            social_icon
            social_icon_font_color
            social_icon_link {
              url
            }
          }
        }
        ... on PrismicFooterBodyGspannPolicy {
          id
          slice_type
          items {
            policy_name {
              text
            }
            policy_link {
              url
            }
            policy_font_color
          }
        }
        ... on PrismicFooterBodyFooterMenu {
          id
          slice_type
          primary {
            footer_menu_type {
              text
            }
            footer_menu_link {
              url
            }
            menu_type_font_color
          }
          items {
            footer_menu_text {
              text
            }
            footer_menu_link {
              url
            }
            footer_menu_font_color
          }
        }
      }
    }
  }
  prismicCareerFooter {
    data {
      footer_background_color
      footer_font_color
      address {
        text
      }
      copyright_text {
        text
      }
      footer_logo {
        url
      }
      footer_description {
        text
      }
      body {
        __typename
        ... on PrismicCareerFooterBodySocialConnection {
          id
          slice_type
          primary {
            social_connection_type {
              text
            }
            social_connection_font_color
          }
          items {
            social_icon
            social_icon_font_color
            social_icon_link {
              url
            }
          }
        }
        ... on PrismicCareerFooterBodyGspannPolicy {
          id
          slice_type
          items {
            policy_name {
              text
            }
            policy_link {
              url
            }
            policy_font_color
          }
        }
        ... on PrismicCareerFooterBodyFooterMenu {
          id
          slice_type
          items {
            footer_menu_text {
              text
            }
            #footer_menu_link {
              #  url
              #}
              footer_menu_font_color
          }
        }
      }
    }
  }
}`;
